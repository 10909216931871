<template>
  <div class="order">
    <div class="user-tit">我的订单</div>
    <div v-if="tableData.length && !loading">
      <table class="table">
        <tr>
          <th>订单号</th>
          <th>认证</th>
          <th>支付金额</th>
          <th>考试有效期</th>
          <th>支付时间</th>
          <th>支付状态</th>
          <th>操作</th>
        </tr>
        <tr v-for="item in tableData" :key="item.id">
          <td>{{item.order_no}}</td>
          <td>{{item.cert && item.cert.name}}</td>
          <td>{{item.price}}</td>
          <td>{{item.toUser && item.toUser.exam_end_time}}</td>
          <td>{{item.toUser && item.toUser.exam_end_time}}</td>
          <td>{{orderStatus[item.status]}}</td>
          <td>
            <div class="table-btn" v-if="item.status == 1" @click="goStudy(item)">去学习</div>
            <div class="table-btn" v-if="item.status == 0 " @click="goBuy(item)">立即购买</div>
            <div class="table-btn" v-if="item.status == 2 || item.status == 3" @click="goBuy(item)">重新购买</div>
          </td>
        </tr>
      </table>
    </div>
    <div class="page" v-if="tableData.length && !loading">
      <el-pagination
          @size-change="changePageSize"
          @current-change="changePage"
          :current-page="page"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>

    </div>

    <div class="no-data" v-if="!tableData.length && !loading">
      <img src="../../assets/images/common/no-data.png" width="223" height="129" />
      <p>还没有订单哦~</p>
    </div>
    <div style="height: 300px;position: relative" v-loading="loading" v-if="loading"></div>
  </div>
</template>

<script>
import util from '@/utils/index.js'
export default {
  name: "order",
  data(){
    return {
      tableData: [],
      orderStatus:{},
      page:1,
      pageSize:10,
      total:0,
      loading:true,
    }
  },
  created(){
    this.getList();
  },
  methods:{
    tableRowClassName({row, rowIndex}) {
      console.log(row,rowIndex)
      if (rowIndex === 0) {
        return 'table-first';
      }
    },
    getList(){
      let params = {
        page:this.page,
        pageSize:this.pageSize
      };
      this.loading = true;
      this.api.user.orderList(params).then((res)=>{
        this.loading = false;
        this.tableData = res.list;
        this.orderStatus = res.statuses;
        this.tableData.forEach((item)=>{
          if(item.toUser){
            item.toUser.exam_end_time = util.timeFormatter(
                new Date((+item.toUser.exam_end_time) * 1000),
                'yyyy-MM-dd hh:mm');
          }

          item.payment_end_time = util.timeFormatter(
              new Date((+item.payment_end_time) * 1000),
              'yyyy-MM-dd hh:mm')

          this.total = Number(res.count);
        })
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changePageSize(pageSize){
      this.page = 1;
      this.pageSize = pageSize;
      this.getList();
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    goStudy(data){
      this.$router.push({
        path:'/attestation/train',
        query:{
          id:data.cert_id
        }
      })
    },
    goBuy(data){
      this.$router.push({
        path:'/attestation/order',
        query:{
          id:data.cert_id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.order{
  .user-tit{
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: bold;
  }
  .table{
    width: 100%;
    color: #666666;
    >tr{
      height: 70px;
      border-bottom: 1px solid $theme-border-gray;
      >th,td{
        text-align: center;
      }
    }
    >tr:first-child{
      height: 56px;
      background: #FAFBFC;
      >th{
        font-weight: bold;
        color: #333333;
      }
    }
  }
  .table-btn{
    margin: 0 auto;
    @include btn(80px,30px,15px,14px,#1664FF)
  }
  .page{
    margin: 20px;
    text-align: right;
  }
}

</style>
<style>
.order .table-first{
  background: #FAFBFC;
  font-weight: bold;
  color: #333333;
}
</style>
